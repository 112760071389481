
@import "~bootstrap/scss/bootstrap";

@import "../../node_modules/bootstrap/scss/bootstrap";

body {
    background: #18466E;
    font-family: 'helvetica-light';
    // text-transform: lowercase;
    color: white;
  }

  @font-face {
    font-family: 'Major Mono Display', monospace;
    src: local('Major Mono Display'), url(../pretty/MajorMonoDisplay-Regular.ttf) format('ttf');
  }

  @font-face {
    font-family: 'Send Flowers', monospace;
    src: local('Send Flowers'), url(../pretty/SendFlowers-Regular.ttf) format('ttf');
  }

  $headings-font-family: 'Major Mono Display', monospace;

  $title-font-family: 'Send Flowers', monospace;

  .the-title {
    font-family: $title-font-family;
  }

  .navbar {
    opacity: 50%;
    padding-bottom: 20px;
  }
  

  //   // With variable
  // .alpha { color: $purple; }

  // // From the Sass map with our `color()` function
  // .beta { color: color("purple"); }
  
  .buttun {
    border-color: transparent!important; 
  }
  
  .first-block {
    background: url(../pretty/uwu.jpg);
    background-size: cover;
    height: 100vh;
    padding-top: 2rem!important;
  }

  .second-block {
    color: gray;
    background: lightsteelblue;
    background-size: cover;
  }

  .thumb {
    max-width: 200px;
    object-fit: cover;
    max-height: 270px;
  }

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: underline;
  color: white;
}

.dropzone-text {
  font-size: larger;
}


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $headings-font-family;
    text-transform: lowercase;
}

#user-badge {
  font-size: small;
}

.table {
  color: white
}